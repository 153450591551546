import {
  GET_NEWS_SUCCESS,
  GET_NEWS_FAIL,
  DELETE_NEWS_SUCCESS,
  DELETE_NEWS_FAIL,
  LOGOUT,
} from "../actions/types";
import _ from "lodash";

const news = (state = {}, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_NEWS_SUCCESS:
      return { ...state, ..._.mapKeys(payload, "_id") };
    case GET_NEWS_FAIL:
      return { ...state };
    case DELETE_NEWS_SUCCESS:
      return _.omit(state, payload);
    case DELETE_NEWS_FAIL:
      return { ...state };
    case LOGOUT:
      return {};
    default:
      return state;
  }
};

export default news;
