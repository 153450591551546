import { useSelector } from "react-redux";
import { Routes, Route, useLocation, Navigate, Outlet } from "react-router-dom";

import "./App.scss";

import BoardAdmin from "./pages/BoardAdmin";
import BoardUser from "./pages/BoardUser";
import Dashboard from "./pages/Dashboard";
import EventsPage from "./pages/Events";
import Home from "./pages/Home";
import HowItWorks from "./pages/HowItWorks";
import Login from "./pages/Login";
import PoolsPage from "./pages/Pools";
import Profile from "./pages/Profile";
import Register from "./pages/Register";
import StreamCreate from "./pages/streams/StreamCreate";
import StreamEdit from "./pages/streams/StreamEdit";
import StreamList from "./pages/streams/StreamList";
import UsersPage from "./pages/Users/Users";
import News from "./pages/News/News";
import NewsCreate from "./pages/News/NewsCreate";
import NewsEdit from "./pages/News/NewsEdit";

// import { logout } from "./actions/auth";
// import { clearMessage } from "./actions/message";

import NavbarTop from "./components/Navbar";
import Footer  from "./components/Footer";
import PoolsCreate from "./components/Pools/PoolsCreate";
import PoolsEdit from "./components/Pools/PoolsEdit";



function RequireAuth() {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const location = useLocation();

  if (!isLoggedIn) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return <Outlet />;
}

const darkMode = localStorage.getItem("dark");

const App = () => {
  return (
    <div className={`${darkMode ? 'dark main' : "main"}`}>
      <NavbarTop />
      <Routes>
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/signup" element={<Register />} />
        <Route exact path={"/"} element={<Home />} />
        <Route element={<RequireAuth />}>
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/pools/edit/:id" element={<PoolsEdit/>} />
          <Route exact path="/pools/add" element={<PoolsCreate />} />
          <Route exact path="/pools" element={<PoolsPage />} />
          <Route exact path="/profile" element={<Profile />} />
          <Route exact path="/streams/edit/:id" element={<StreamEdit />} />
          <Route exact path="/streams/new" element={<StreamCreate />} />
          <Route exact path="/streams" element={<StreamList />} />
          <Route exact path="/events" element={<EventsPage />} />
          <Route exact path="/user" element={<BoardUser />} />
          <Route exact path="/users" element={<UsersPage />} />
          <Route exact path="/admin" element={<BoardAdmin />} />
          <Route exact path="/howitworks" element={<HowItWorks />} />
          <Route exact path="/news/edit/:id" element={<NewsEdit/>} />
          <Route exact path="/news/add" element={<NewsCreate />} />
          <Route exact path="/news" element={<News />} />
          {/* <Route exact path="/mod" element={<BoardModerator />} /> */}
        </Route>
      </Routes>
      <Footer/>
    </div>
  );
};

export default App;
