import {
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAIL,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  LOGOUT,
} from "../actions/types";
import _ from "lodash";

const users = (state = {}, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_USERS_SUCCESS:
      return { ...state, ..._.mapKeys(payload, "username") };
    case FETCH_USERS_FAIL:
      return { ...state };
    case DELETE_USER_SUCCESS:
      return _.omit(state, payload);
    case DELETE_USER_FAIL:
      return { ...state };
    case LOGOUT:
      return {};
    default:
      return state;
  }
};

export default users;
