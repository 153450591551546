import {
  FETCH_POOLS_SUCCESS,
  FETCH_POOLS_FAIL,
  DELETE_POOL_SUCCESS,
  DELETE_POOL_FAIL,
  LOGOUT,
} from "../actions/types";
import _ from "lodash";

const pools = (state = {}, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_POOLS_SUCCESS:
      return { ...state, ..._.mapKeys(payload, "_id") };
    case FETCH_POOLS_FAIL:
      return { ...state };
    case DELETE_POOL_SUCCESS:
      return _.omit(state, payload);
    case DELETE_POOL_FAIL:
      return { ...state };
    case LOGOUT:
      return {};
    default:
      return state;
  }
};

export default pools;
