import axios from "axios";
import authHeader from "./auth-header";

const getUser = async () => {
  const response = await axios.get(
    process.env.REACT_APP_API_URL + "/user/info",
    {
      headers: authHeader(),
    }
  );
  return response.data;
};

const newAvatar = async (token) => {
  return await axios.post(
    process.env.REACT_APP_API_URL + "/user/newavatar",
    { token: token },
    { headers: authHeader() }
  );
};

const addStakeName = async (name, token) => {
  return await axios.put(
    process.env.REACT_APP_API_URL + "/user/stake",
    { token: token, stake: name },
    { headers: authHeader() }
  );
};

// const getAdminBoard = async () => {
//   return await axios.get(process.env.REACT_APP_API_URL + "admin", { headers: authHeader() });
// };

const user = {
  getUser,
  newAvatar,
  addStakeName,
};

export default user;
