import {
  GET_VIDEOS_SUCCESS,
  FETCH_STREAM_SUCCESS,
  GET_VIDEOS_FAIL,
  CREATE_LIVE_INPUT_SUCCESS,
  CREATE_LIVE_INPUT_FAIL,
  FETCH_STREAM_FAIL,
  DELETE_VIDEO_SUCCESS,
  DELETE_VIDEO_FAIL,
  EDIT_LIVE_INPUT_SUCCESS,
  EDIT_LIVE_INPUT_FAIL,
  LOGOUT,
} from "../actions/types";
import _ from "lodash";

const streams = (state = {}, action) => {
  const { type, payload } = action;

  switch (type) {
    case CREATE_LIVE_INPUT_SUCCESS:
      return { ...state, [payload.uid]: payload};
    case CREATE_LIVE_INPUT_FAIL:
      return {  ...state };
      
    case GET_VIDEOS_SUCCESS:
      return { ...state, ..._.mapKeys(payload, "uid") };
    case GET_VIDEOS_FAIL:
      return {  ...state };

    case FETCH_STREAM_SUCCESS:
      return { ...state, [payload.id]: payload };
    case FETCH_STREAM_FAIL:
      return {  ...state };

    case EDIT_LIVE_INPUT_SUCCESS:
      return { ...state };
    case EDIT_LIVE_INPUT_FAIL:
      return { ...state};

    case DELETE_VIDEO_SUCCESS:
      return _.omit(state, payload);
    case DELETE_VIDEO_FAIL:
      return {  ...state };

    case LOGOUT:
      return { };
    default:
      return state;
  }
};

export default streams;