import { useState, useEffect, useRef, useContext } from "react";
import { useSelector } from "react-redux";
import { to_Decrypt, to_Encrypt } from "../../aes.js";
import { Button, Form, Icon, Tag, Notification } from "react-bulma-components";
import {
  UsersIcon,
  BanIcon,
  TrashIcon,
  ChartSquareBarIcon,
  FireIcon,
} from "@heroicons/react/outline";
import useSocket from "../../hooks/socket";
import { SocketContext } from "../../context/socket";
import jwt_decode from "jwt-decode";
import { getLang } from "../../utils/utils";
import PollCreate from "../../components/PollCreate";
import PollShow from "../../components/PollShow";

function Chat() {
  const [text, setText] = useState("");
  const [messages, setMessages] = useState([]);
  const { user: currentUser } = useSelector((state) => state.auth);
  const token = useSelector((state) => state?.auth.user.accessToken);
  const [username] = useState(currentUser?.username);
  const [roomname] = useState(1);
  const [numberOfUsers, setNumberOfUsers] = useState(0);
  const [startChat, setStartChat] = useState(false);
  const [banned, setBanned] = useState(false);
  // const [openMenu, setOpenMenu] = useState(false);
  const [showModerator, setShowModerator] = useState(false);
  // const dispatch = useDispatch();
  const chatRef = useRef(null);
  const messageRef = useRef(null);
  const socket = useContext(SocketContext);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isPollBoxOpen, setIsPollBoxOpen] = useState(false);
  const [isPollActive, setIsPollActive] = useState(false);
  const [highlight, setHighlight] = useState();

  const {
    connectSocket,
    subscribeToMessages,
    getHistory,
    getHighlight,
    getNumberOfUsers,
    disconnectSocket,
    joinToRoom,
    sendMessage,
    deleteMessage,
    highlightMessage,
    banUser,
    handleBanned,
    pollNew,
    pollClosed,
  } = useSocket(socket);

  // const dispatchProcess = (encrypt, msg, cipher) => {
  //   dispatch(process(encrypt, msg, cipher));
  // };

  useEffect(() => {
    setIsAdmin(jwt_decode(token).roles.includes("ROLE_ADMIN"));
    if (currentUser) {
      setShowModerator(
        jwt_decode(currentUser.accessToken).roles.includes("ROLE_MODERATOR")
      );
    } else {
      setShowModerator(false);
    }
    // eslint-disable-next-line
  }, [currentUser]);

  useEffect(() => {
    connectSocket();
    subscribeToMessages((err, data) => {
      // console.log(data);
      const ans = to_Decrypt(data.text, data.username);
      const date = new Date(data.date);

      setMessages((prev) => [
        ...prev,
        {
          text: ans,
          userId: data.userId,
          msgId: data.msgId,
          username: data.username,
          bgColor: data.bgColor,
          color: data.color,
          date: `${date.toLocaleString(getLang)}`,
        },
      ]);
    });
    getHistory((err, history) => {
      let temp = history.map((data) => {
        return {
          ...data,
          text: to_Decrypt(data.text, data.username),
        };
      });

      setMessages(() => [...temp]);
    });
    getHighlight((err, message) => {
      // let temp = history[-1].map((data) => {
      //   return {
      //     ...data,
      //     text: to_Decrypt(data.text, data.username),
      //   };
      // });
      console.log(message);
      setHighlight({
        ...message,
        text: to_Decrypt(message.text, message.username),
      });
    });
    getNumberOfUsers((err, number) => {
      setNumberOfUsers(number);
    });
    handleBanned((isBanned) => {
      console.log("banned data cb", isBanned);
      if (isBanned) {
        setBanned(true);
      }
    });
    pollNew(() => {
      setIsPollActive(true);
    });
    pollClosed(() => {
      setIsPollActive(false);
    });
    return () => {
      disconnectSocket();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (messages.length > 0) {
      scrollToBottom();
    }
    if (messages.length > 250) {
      setMessages((messages) => messages.slice(1));
    }
  }, [messages]);

  const handleJoinToRoom = (data) => {
    joinToRoom(data, (data) => {
      console.log(data);
    });
    setStartChat(true);
  };

  const sendData = () => {
    if (text !== "") {
      //encrypt here
      const ans = to_Encrypt(text);
      sendMessage(ans, (cb) => {});
    }
    setText("");
  };

  const scrollToBottom = () => {
    chatRef.current.scrollTop = messageRef.current.clientHeight;
  };

  const handleBanUser = (id) => {
    if (window.confirm(`Do you really want to ban user ?`)) {
      banUser(id);
    }
  };

  const handleDeleteMessage = (id) => {
    if (window.confirm(`Do you really want delete message ?`)) {
      deleteMessage(id, (cb) => {});
    }
  };

  const handleHighlightMessage = (id) => {
    highlightMessage(id, (cb) => {});
  };

  const handleDeleteHighlight = () => {
    setHighlight(null);
  };

  const handleOpenPoll = () => {
    setIsPollBoxOpen(!isPollBoxOpen);
  };

  const formatDate = (date) => {
    const formattedDate = new Date(date);
    return formattedDate.toLocaleString(getLang, {
      dateStyle: "short",
      timeStyle: "short",
    });
  };

  const { Input } = Form;

  return (
    <div
      className={`chat-wrap h-100 position-relative ${
        isPollBoxOpen && "chat-wrap-opened"
      }`}
    >
      <div className="is-flex is-justify-content-space-between">
        <h2 className="is-size-3">Chat</h2>
        <span>
          {startChat && (
            <Tag.Group gapless>
              <Tag color="dark" className="mb-0">
                <Icon>
                  <UsersIcon width={16} height={16} />
                </Icon>
              </Tag>
              <Tag color="link" className="mb-0">
                {numberOfUsers}
              </Tag>
            </Tag.Group>
          )}
        </span>
      </div>

      {!startChat && (
        <div className="content">
          <p>Rules:</p>
          <ul>
            <li>Be kind</li>
            <li>Don't spam</li>
            <li>Don't be an idiot</li>
            <li>Swearing is allowed</li>
          </ul>
          <Button
            color="primary"
            onClick={() => handleJoinToRoom({ username, roomname })}
            disabled={startChat || banned}
            fullwidth
          >
            Join
          </Button>
        </div>
      )}
      {startChat && (
        <>
          <div className="chat" ref={chatRef}>
            <div className="chat-messages" ref={messageRef}>
              {highlight && (
                <Notification className="mb-2 p-3" color={"warning"} light>
                  {highlight.username}: {highlight.text}{" "}
                  <Button remove onClick={handleDeleteHighlight} />
                </Notification>
              )}

              {messages.map((msg, i) => {
                return (
                  <div className="mess" key={`${username}-${i}`}>
                    {showModerator && (
                      <span className="message-options">
                        {msg.username !== username && (
                          <Tag
                            title="Chat ban"
                            color="danger"
                            className="m-0"
                            onClick={() => handleBanUser(msg.userId)}
                          >
                            <Icon>
                              <BanIcon width={16} height={16} />
                            </Icon>
                          </Tag>
                        )}
                        <Tag
                          title="Delete message"
                          color={msg.deleted ? "dark" : "warning"}
                          className="ml-1"
                          onClick={() => {
                            !msg.deleted && handleDeleteMessage(msg.msgId);
                          }}
                        >
                          <Icon>
                            <TrashIcon width={16} height={16} />
                          </Icon>
                        </Tag>
                        <Tag
                          title="Highlight message"
                          color={"success"}
                          className="ml-1"
                          onClick={() => {
                            !msg.highlight && handleHighlightMessage(msg.msgId);
                          }}
                        >
                          <Icon>
                            <FireIcon width={16} height={16} />
                          </Icon>
                        </Tag>
                      </span>
                    )}

                    <div
                      className="message message-line"
                      title={formatDate(msg.date)}
                    >
                      <strong
                        style={{ color: msg.bgColor }}
                        className="message-name"
                        // onClick={handleMenuOpen}
                      >
                        {msg.username}:
                      </strong>{" "}
                      <span className="message-text">
                        {msg.text} {msg.deleted}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="is-flex mt-3 chat-input">
            <Input
              type="text"
              placeholder={banned ? "You have been banned" : "Send message"}
              value={text}
              disabled={banned}
              onChange={(e) => setText(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  sendData();
                }
              }}
              className="mr-3"
            />
            <Button color="primary" onClick={sendData} disabled={banned}>
              Send
            </Button>
            <Button
              onClick={handleOpenPoll}
              color={`${isPollActive ? "danger" : "primary"}`}
              className={`ml-3 poll-open-button ${isPollActive && "live-tag"}`}
            >
              <Icon>
                <ChartSquareBarIcon width={24} height={24} />
              </Icon>
            </Button>
          </div>
        </>
      )}

      <div
        className={`p-0 ${isPollBoxOpen ? "poll-box opened" : "poll-box"}`}
        style={{ background: "transparent" }}
      >
        <PollShow />
        {isAdmin || (showModerator && !isPollActive && <PollCreate />)}
      </div>
    </div>
  );
}

export default Chat;
