export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const LOGOUT = "LOGOUT";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const GET_VIDEOS_SUCCESS = "GET_VIDEOS_SUCCESS";
export const GET_VIDEOS_FAIL = "GET_VIDEOS_FAIL";

export const CREATE_LIVE_INPUT_SUCCESS = 'CREATE_LIVE_INPUT_SUCCESS';
export const CREATE_LIVE_INPUT_FAIL = 'CREATE_LIVE_INPUT_FAIL';

export const FETCH_STREAM_SUCCESS = 'FETCH_STREAM_SUCCESS';
export const FETCH_STREAM_FAIL = 'FETCH_STREAM_FAIL';

export const DELETE_VIDEO_SUCCESS = 'DELETE_VIDEO_SUCCESS';
export const DELETE_VIDEO_FAIL = 'DELETE_VIDEO_FAIL';

export const EDIT_LIVE_INPUT_SUCCESS = 'EDIT_LIVE_INPUT_SUCCESS';
export const EDIT_LIVE_INPUT_FAIL = 'EDIT_LIVE_INPUT_FAIL';

export const EDIT_VIDEO_SUCCESS = 'EDIT_VIDEO_SUCCESS';
export const EDIT_VIDEO_FAIL = 'EDIT_VIDEO_FAIL';

export const FETCH_POOLS_SUCCESS = 'FETCH_POOLS_SUCCESS';
export const FETCH_POOLS_FAIL = 'FETCH_POOLS_FAIL';

export const ADD_POOL_SUCCESS = 'ADD_POOL_SUCCESS';
export const ADD_POOL_FAIL = 'ADD_POOL_FAIL';

export const DELETE_POOL_SUCCESS = 'DELETE_POOL_SUCCESS';
export const DELETE_POOL_FAIL = 'DELETE_POOL_FAIL';

export const EDIT_POOL_SUCCESS = 'EDIT_POOL_SUCCESS';
export const EDIT_POOL_FAIL = 'EDIT_POOL_FAIL';

export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAIL = 'FETCH_USERS_FAIL';

export const GET_NEW_AVATAR_SUCCESS = 'GET_NEW_AVATAR_SUCCESS';
export const GET_NEW_AVATAR_FAIL = 'GET_NEW_AVATAR_FAIL';

export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAIL = 'FETCH_USER_FAIL';

export const ADD_STAKE_NAME_SUCCESS = 'ADD_STAKE_NAME_SUCCESS';
export const ADD_STAKE_NAME_FAIL = 'ADD_STAKE_NAME_FAIL';

export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL';

export const CHATBAN_USER_SUCCESS = 'CHATBAN_USER_SUCCESS';
export const CHATBAN_USER_FAIL = 'CHATBAN_USER_FAIL';

export const FETCH_BANNED_USERS_SUCCESS = 'FETCH_BANNED_USERS_SUCCESS';
export const FETCH_BANNED_USERS_FAIL = 'FETCH_BANNED_USERS_FAIL';

export const ADD_NEWS_SUCCESS = 'ADD_NEWS_SUCCESS';
export const ADD_NEWS_FAIL = 'ADD_NEWS_FAIL';

export const GET_NEWS_SUCCESS = 'GET_NEWS_SUCCESS';
export const GET_NEWS_FAIL = 'GET_NEWS_FAIL';

export const DELETE_NEWS_SUCCESS = 'DELETE_NEWS_SUCCESS';
export const DELETE_NEWS_FAIL = 'DELETE_NEWS_FAIL';