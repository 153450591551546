import {
    FETCH_BANNED_USERS_SUCCESS,
    FETCH_BANNED_USERS_FAIL,
    LOGOUT,
  } from "../actions/types";
  import _ from "lodash";
  
  const banned = (state = {}, action) => {
    const { type, payload } = action;
  
    switch (type) {
      case FETCH_BANNED_USERS_SUCCESS:
        return { ...state, ..._.mapKeys(payload, "username")};
      case FETCH_BANNED_USERS_FAIL:
        return { ...state };
      case LOGOUT:
        return {};
      default:
        return state;
    }
  };
  
  export default banned;
  