import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import store from "./store";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      {/* <App /> */}

      <div className="hero-bg">
        <div className="hero-body is-flex is-justify-content-center is-align-items-center is-flex-direction-column hero-body">
          <h1 class="hero-text has-text-centered is-size-3">
            🎰 <br></br> gambler tv
            <hr></hr>
          </h1>
          <div className="hero-text">coming soon!</div>
        </div>
      </div>

      {/* <ComingSoon title="Coming Soon" subtitle="- Gambler TV - " bgColor="#2c2898" textColor="#fcb900" illustration="development" /> */}
    </BrowserRouter>
  </Provider>
);

serviceWorker.unregister();
