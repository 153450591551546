import { combineReducers } from "redux";
import auth from "./auth";
import message from "./message";
import streams from "./streams";
import process from "./process";
import pools from "./pools";
import users from "./users";
import banned from "./banned";
import news from "./news";

export default combineReducers({
  auth,
  message,
  streams,
  process,
  pools,
  users,
  banned,
  news,
});
